import React from 'react';
import { coursesData } from '@/constant/HomepageConst';

import CourseCard from '../common/CourseCard';

const CoursesOffered = () => {
  return (
    <div className="mb-10 mt-20 bg-primary-gray py-8 font-inter">
      <div className="lg:mx-auto lg:w-4/5 lg:max-w-screen-xl">
        <h2 className="mb-3 text-center text-3xl font-bold text-primary-green">Courses Offered</h2>
        <p className="mx-auto w-3/5 text-center text-base text-dark-gray ">
          At Medway, we offer a range of tailored courses designed to prepare students effectively for their medical
          entrance exams:
        </p>
        <div className="my-10 flex flex-wrap justify-center gap-6">
          {coursesData.map((course) => (
            <CourseCard key={course.id} title={course.title} description={course.description} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoursesOffered;
