export const testimonials = [
  {
    id: 1,
    name: 'Amit Kumar, Delhi',
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/52d43ed4-4922-11ef-9913-b7d393cbe9e0.jpg',
    review:
      'The Medway app has transformed my NEET-PG preparation. The live classes are incredibly interactive,\r\nand the instant doubt resolution feature is a lifesaver. I feel more confident and well-prepared for my\r\nexams.',
    rating: '4.50',
  },
  {
    id: 2,
    name: 'Priya Sharma, Mumbai',
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/1dd4ea54-9381-11ef-a611-8624bca5fa53.jpg',
    review:
      'I love how the Medway app offers a structured study schedule that keeps me disciplined and focused.\r\nThe personalized feedback from instructors helps me understand my mistakes and improve\r\ncontinuously.',
    rating: '4.60',
  },
  {
    id: 3,
    name: 'Rahul Verma, Bangalore',
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/a1206838-4922-11ef-9913-b7d393cbe9e0.jpg',
    review:
      'The flexibility of the Medway app is amazing. I can revisit recorded sessions anytime, which is perfect\r\nfor reviewing complex topics. The app has made learning convenient and effective for me.',
    rating: '4.30',
  },
  {
    id: 4,
    name: 'Sneha Reddy, Hyderabad',
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/bdc80bf8-4922-11ef-9913-b7d393cbe9e0.jpg',
    review:
      'The interactive live classes on the Medway app keep me engaged and motivated. The immediate doubt\r\nresolution and peer discussions make learning so much more enjoyable and efficient.',
    rating: '4.60',
  },
  {
    id: 5,
    name: 'Vikas Patel, Ahmedabad',
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/dc679542-4922-11ef-9913-b7d393cbe9e0.jpg',
    review:
      "Medway's app keeps me updated with the latest NEET-PG syllabus and exam patterns. The adaptive\r\nteaching methods and real-time feedback ensure that I'm always on the right track.",
    rating: '4.80',
  },
];
