import React from 'react';
import Image from 'next/image';
import RightArrowSvg from '@/assets/Icons/common/right-arrow.svg';
import { facultiesData } from '@/constant/FacultyData';
import { Slider } from '@/HOC/SliderComponent';
import { SwiperSlide } from 'swiper/react';

import FacultyCard from '../common/FacultyCard';

const OurFaculties = () => {
  return (
    <div className="relative mx-auto px-4 py-8 lg:w-4/5 lg:max-w-screen-xl">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-left text-4xl font-bold text-secondary-green">Our Faculties</h2>
          <p className="mt-2 max-w-2xl text-left text-slate-gray">
            At Medway, our dedicated faculty combines expertise and passion to nurture future medical professionals.
          </p>
        </div>
        <div className="flex items-center justify-between gap-4">
          <button
            id="custom-faculty-prev"
            className="swiper-next-button flex size-8 rotate-180 items-center justify-center rounded-full border-2 border-primary-green text-primary-green hover:bg-primary-green hover:text-white"
          >
            <Image src={RightArrowSvg} alt="" className="ml-1 w-4" />
          </button>
          <button
            id="custom-faculty-next"
            className="swiper-next-button flex size-8 items-center justify-center rounded-full border-2 border-primary-green text-primary-green hover:bg-primary-green hover:text-white"
          >
            <Image src={RightArrowSvg} alt="" className="ml-1 w-4" />
          </button>
        </div>
      </div>
      <div className="mt-8">
        <Slider
          options={{
            navigation: {
              nextEl: '#custom-faculty-next',
              prevEl: '#custom-faculty-prev',
            },
            spaceBetween: 10,
            slidesPerView: 3,
          }}
        >
          {facultiesData.map((faculty, index) => {
            const gradient =
              index % 2 === 0
                ? 'bg-gradient-to-br from-white via-transparent to-[#468837]'
                : 'bg-gradient-to-br from-white via-transparent to-[#eaa7f6]    ';

            return (
              <SwiperSlide key={faculty.id}>
                <FacultyCard {...faculty} gradient={gradient} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default OurFaculties;
