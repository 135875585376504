import Image from 'next/image';
import Link from 'next/link';
import TestSeriesBg from '@/assets/Images/Banner/bg-test-series.png';
import { testData } from '@/constant/CommonData';
import { toKebabCase } from '@/utils/camelCaseConverter';

const TestSeries = () => {
  return (
    <div className="relative z-10 my-10 bg-light-gray pt-10 text-center font-inter">
      <h2 className="text-4xl font-semibold leading-normal text-secondary-green">Smart Test Series</h2>
      <p className="mb-8 mt-2 text-base text-dark-gray">
        Choose from Subject, Mini, and Grand tests to strengthen your prep.
      </p>
      {testData.map((data, index) => {
        return (
          <div key={index} className="flex flex-col justify-center md:flex-row">
            {Object.values(data).map((test: any) => {
              const link = toKebabCase(test.title);
              return (
                <div key={test.title} className="flex flex-col items-center pt-4">
                  <h3 className="mt-4 text-2xl font-semibold text-black">{test.title}</h3>
                  <ul className="mt-2 list-inside list-disc space-y-1 text-sm font-normal text-secondary-gray">
                    {test.pointers.map((point: string, i: number) => (
                      <li key={i} className="text-sm">
                        {point}
                      </li>
                    ))}
                  </ul>
                  <Link href={`/tests/${link}`} className="h-full">
                    <Image
                      src={test.imageSrc}
                      alt={`${test.title} image`}
                      width={320}
                      height={600}
                      className="h-full rounded-lg"
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}

      <div className="absolute top-5 -z-10 flex w-full items-center justify-center overflow-hidden blur-lg">
        <Image src={TestSeriesBg} alt="Test series background" width={950} />
      </div>
    </div>
  );
};

export default TestSeries;
