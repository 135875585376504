export const toCamelCase = (str: string) => {
  return str
    .replace(/-([a-z])/g, (match, group1) => group1.toUpperCase())
    .replace(/^[a-z]/, (match) => match.toLowerCase());
};

export const toKebabCase = (str: string) => {
  return `${str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '')}`;
};
