import { FacultyCardProps } from '@/types/const';

export const facultiesData: FacultyCardProps[] = [
  {
    id: 19,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Priyansh Jain',
    total_experience: 9,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/c8e5446c-4781-11ef-9913-b7d393cbe9e0.png',
    description:
      "MBBS (Gold Medalist) MD (General Medicine) - JLN Medical college  Consultant Physician & Assistant Professor  USMLE/MRCP qualified 7 International Publications National Level Faculty - NEET-PG, FMGE/NEXT President's Award (Scouts) Selected for Research and Training at NIH, USA (sponsored by USA Government) Delivered lectures in International and National Medical Colleges",
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/c8e5446f-4781-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/c8e54472-4781-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 10,
    additional_info: {},
    specialization: 'Medicine',
    certification_count: 10,
    lecture: 89,
  },
  {
    id: 20,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr. Bhavika Bansal',
    total_experience: 3,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/951f3b72-7325-11ef-bc4f-c6b30c100846.png',
    description:
      'Dr Bhavika Bansal  MBBS, MD (AIIMS New Delhi), DNB ,FICO ,FSCEH Ophthalmologist (specialist in pediatric ophthalmology and neuro-ophthalmology)  Several publications on the name  ⁠Contributed to a chapter in the book -The art of refractive surgery  Ability to make Ophthalmology easy to understand and remember   ⁠Will be sharing latest updates and advancements  With a passion for both clinical practice and academics , I will simplify complex ophthalmic concepts and mentor students.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/9528a39c-7325-11ef-bc4f-c6b30c100846.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/95302626-7325-11ef-bc4f-c6b30c100846.mp4',
    date_of_joining: '2024-09-12',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Ophthalmology',
    certification_count: 0,
    lecture: 35,
  },
  {
    id: 21,
    gender: '1',
    headline: 'MBBS',
    name: 'Dr. Salman Kamal',
    total_experience: 10,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/730e2212-4785-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr Salman Kamal  Microbiology  He has been educating doctors with a decade of experience in his hands. His   passion and dedication towards teaching is depicted by the love shown by the   students who has attended his classes.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/730e2215-4785-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/730e2218-4785-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Microbiology',
    certification_count: 0,
    lecture: 63,
  },
  {
    id: 22,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Bharath Kumar VD',
    total_experience: 7,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/8732d200-4786-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Bharath Kumar VD is a versatile healthcare professional with expertise in pharmacology, diabetes management, medical education, and research. He serves as an Assistant Professor at BGS Global Institute of Medical Sciences and is actively involved in teaching, mentoring, and research initiatives. As President of TREAT Diabetes Foundation, he leads efforts in diabetes prevention, education, and awareness.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/4d627f1f-4f56-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/9202750d-4f56-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-04-30',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Pharmacology',
    certification_count: 0,
    lecture: 64,
  },
  {
    id: 23,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr. Swati Singh',
    total_experience: 12,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/f5f600e0-4786-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr Swati Singh MD Anesthesiology from postgraduate Institute of Medical education and Research, Chandigarh  Residency from Maulana Azad Medical college, New Delhi. Currently Professor of Anesthesiology at Indira Gandhi Institute of Medical SCIENCES Patna. Passion for teaching and Research  10 years teaching experience on various platform. 100plus Research article in medline index journal. Runs a website for Pg course in Anaesthesiology learn anaesthesia. Com National instructor for various courses like BLS, ACLS ,NELS.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/f5f600e3-4786-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/f5f600e6-4786-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Anaesthesia',
    certification_count: 0,
    lecture: 20,
  },
  {
    id: 24,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Sanjay Khatri',
    total_experience: 22,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/76194ef8-4787-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Sanjay Khatri is a distinguished Senior Consultant Pediatric and Interventional Cardiologist at Mahatma Gandhi Medical College. He completed his MBBS from SPMC, Bikaner, Rajasthan, in the 1991 batch. Dr. Khatri pursued his MD in Pediatrics from SMS, Jaipur, and further specialized in pediatric cardiology at MMM, Chennai.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/76194efb-4787-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/76194efe-4787-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Paediatrics',
    certification_count: 0,
    lecture: 57,
  },
  {
    id: 25,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Sushant Soni',
    total_experience: 10,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/026751c0-4788-11ef-9913-b7d393cbe9e0.png',
    description:
      'He is Dr. Sushant Soni; MBBS (MAMC); MD Pathology; Hemat-Oncology (AIIMS Delhi). With 2 International awards and Numerous National ones; he is an academician and book author with more than 10 years of teaching experience. He mentors  more than 10 thousand students every year to realise their dream for NEET PG and FMGE.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/026751c3-4788-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/026751c6-4788-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 4,
    additional_info: {},
    specialization: 'Pathology',
    certification_count: 4,
    lecture: 53,
  },
  {
    id: 26,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr. Shefali',
    total_experience: 5,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/1b9dfc10-4f56-11ef-9913-b7d393cbe9e0.png',
    description:
      "Dr. Shefali Tyagi completed her MBBS from King George's Medical University (KGMU), Lucknow, as part of the 2005 batch. She pursued her postgraduate studies in Obstetrics and Gynecology from Baba Raghav Das Medical College (BRDMC), Gorakhpur.",
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/9b929ff5-4e37-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/64f0deb6-4788-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'OBS & Gyane',
    certification_count: 0,
    lecture: 81,
  },
  {
    id: 27,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Aditya Pratap Singh Chauhan',
    total_experience: 3,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/ea63e54a-5671-11ef-bdc2-1a86aa163d1d.png',
    description:
      'Dr. Aditya Pratap Singh Chauhan  Forensic Medicine & Toxicology Expert  - MBBS, MD (Forensic Medicine & Toxicology) from AIIMS, New Delhi - Award-winning professional with 4 National Awards and 1 International Award - Renowned for making Forensic Medicine & Toxicology easy to learn - Dedicated to sharing latest updates and advancements  Being an expert in Forensic Medicine and Toxicology, i can make your complex concepts simple. I share my  expertise through engaging teaching methods, ensuring students grasp even the most challenging topics with ease. Stay updated with the latest developments in Forensic Medicine and Toxicology through insights and guidance.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/ea7a530c-5671-11ef-bdc2-1a86aa163d1d.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/ea91bda8-5671-11ef-bdc2-1a86aa163d1d.mp4',
    date_of_joining: '2024-08-09',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Forensic Medicine',
    certification_count: 0,
    lecture: 34,
  },
  {
    id: 28,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr. Monalisa',
    total_experience: 10,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/97b0bc16-4789-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr Monalisa  MBBS from KMCH.  MD Anatomy from  Armed Forces Medical College Pune.  Senior Resident from Lady Hardinge Medical College New Delhi. Currently working as Assistant Professor Anatomy.  Passionate about conceptual Anatomy teaching since last 10 years.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/97b0bc19-4789-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/97b0bc1c-4789-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 15,
    additional_info: {},
    specialization: 'Anatomy',
    certification_count: 15,
    lecture: 102,
  },
  {
    id: 29,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Shubham Mittal',
    total_experience: 4,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/f874f918-4789-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Shubham Mittal completed his MBBS from BJ Medical College, Pune, as part of the 2013-2019 batch. He pursued his postgraduate studies from 2019-2022 at PGI (Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences) in Rohtak, Haryana.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/f874f91b-4789-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/0303241f-530c-11ef-9919-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 21,
    additional_info: {},
    specialization: 'ENT',
    certification_count: 21,
    lecture: 80,
  },
  {
    id: 30,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Priyansh Jain',
    total_experience: 9,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/2512233c-478b-11ef-9913-b7d393cbe9e0.png',
    description:
      "MBBS (Gold Medalist) MD (General Medicine) - JLN Medical college  Consultant Physician & Assistant Professor  USMLE/MRCP qualified 7 International Publications National Level Faculty - NEET-PG, FMGE/NEXT President's Award (Scouts) Selected for Research and Training at NIH, USA (sponsored by USA Government) Delivered lectures in International and National Medical Colleges",
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/2512233f-478b-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/25122342-478b-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Biochemistry',
    certification_count: 0,
    lecture: 36,
  },
  {
    id: 31,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Himanshu Gupta',
    total_experience: 6,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/bf459b78-478b-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Himanshu Gupta has an impressive academic background, having completed his MBBS and MD in radiodiagnosis from Maulana Azad Medical College, followed by senior residency at G B Pant Hospital in New Delhi. He achieved notable rankings in competitive exams such as AIPMT 2011 and NEET PG 2018.Since 2018, Dr. Gupta has been actively teaching and inspiring students through his YouTube channel and various online platforms. His teaching style is particularly noted for effectively explaining complex topics in a concise manner, making difficult subjects more accessible to learners.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/bf459b7b-478b-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/bf459b7e-478b-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Radiology',
    certification_count: 0,
    lecture: 23,
  },
  {
    id: 32,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Sandeep Govil',
    total_experience: 20,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/94a7734c-5892-11ef-be41-3a2186b55b98.png',
    description:
      'Dr.Sandeep Govil is not who has earned the highest academic degree, D.N.B (psychiatry), Nevertheless four Gold Medals in His M.B.B.S but he is Philanthropist, Pro-Patient and in particular passionate professional. He received his first degree from a reputed and prestigious Government Medical College, Bellary , Karnataka .  He Accomplished post graduation from a World Renowned Institute IHBAS (Institute Of Human Behaviour and Allied Sciences ). He has been Trained by Finest Teachers of Psychiatry, which is reflected in his practice. This Institute is involved in the and influential in taking the revolution of Mental Health to Common Man. "He enclose a unique charisma " that along with medicines , Dr.Sandeep Govil provides a full range of clinical services from child , adolescent, young to older person. He takes advantage of psychotherapy and appropriate social services. He believes in holistic treatment of his clients. He possess a special acumen for Schizophrenia and Bipolar disorder , which were his thesis , research topic. A rare amalgamation of the experience ranging from Working and Teaching in Tertiary care Psychiatry institute and expert in handling the Resistant cases in Psychiatry, to the hard core ground level community psychiatric services and deaddiction for the Homeless people in Delhi. Handled many drug research trials in institute. He had been the Incharge of the Drug deaddiction Unit and Mental Health Unit of the Tihar Jail for 4 years . Has 15 National and International Publications. Author of the Book" Psychiatry Made Easy" Take lectures of Psychiatry for medical graduates across country and Abroad. Attended and presented papers at many National and International Psychiatric Conferences.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/94b3825e-5892-11ef-be41-3a2186b55b98.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/94ccbcd8-5892-11ef-be41-3a2186b55b98.mp4',
    date_of_joining: '2024-08-12',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Psychaitry',
    certification_count: 0,
    lecture: 19,
  },
  {
    id: 33,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr. Shivangi Rana',
    total_experience: 4,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/19a2a6a0-478d-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Shivangi Rana completed her MBBS from BJ Medical College, Pune, as part of the 2013-2019 batch. She is currently a final-year resident in Dermatology at ANH Hospital, Visakhapatnam',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/19a2a6a3-478d-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/19a2a6a6-478d-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Dermatology',
    certification_count: 0,
    lecture: 63,
  },
  {
    id: 34,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Rama Shankar',
    total_experience: 15,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/ab943ff6-478d-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr. Rama Shankar, MD in PSM, brings 15 years of extensive experience in both teaching and working in various renowned organizations such as WHO, UNICEF, JHPIEGO, and the WISH Foundation.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/ab943ff9-478d-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/ab943ffc-478d-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'PSM',
    certification_count: 0,
    lecture: 113,
  },
  {
    id: 35,
    gender: '1',
    headline: 'MBBS, MD',
    name: 'Dr. Mukul Mohindra',
    total_experience: 10,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/63ae7cd6-478f-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr Mukul is an extremely passionate teacher and a dedicated Orthopedic surgeon. He specialized in Orthopaedics from PGIMS Rohtak, a premier institute for orthopaedic training in the region. Thereafter he served as Senior Resident in Maulana Azad Medical College before securing the top merit position in FNB exam for Arthroscopy and Sports Medicine training. He is presently working as a Consultant in Sports Injury Centre, Safdarjung Hospital and VMM College, New Delhi. Having authored popular books like Fundamentals of Orthopedics and more, he has keen aptitude to stay in touch with latest trends. Apart from his bright academic record, his zeal for teaching, make him a popular and sought out teacher amongst the undergraduates and postgraduates. With over a decade of teaching experience and his amazing teaching skills and his ability to relate himself to his students, his lectures are a pleasure to watch and master the subject.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/63ae7cd9-478f-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/63ae7cdc-478f-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 5,
    additional_info: {},
    specialization: 'Orthopaedic',
    certification_count: 5,
    lecture: 11,
  },
  {
    id: 36,
    gender: '2',
    headline: 'MBBS, MD',
    name: 'Dr.  Reena Kaur',
    total_experience: 11,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/80793f76-4821-11ef-9913-b7d393cbe9e0.png',
    description:
      'Dr Reena Kaur Ruprai, A dedicated  professional MBBS, MD in Physiology from Indira Gandhi Government Medical College Nagpur,  further enriched teaching profile in research, counselling and medical education. Working as An Associate Professor, with 10 plus teaching experience to undergraduate and post graduate students. With a passion and devotion of teaching extended teaching skills to FMGE, NEET PG ,MDS and INICET aspirants.  Taking physiology from classroom to clinics: integration, problem based and clinical application incorporated in teaching.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/80793f79-4821-11ef-9913-b7d393cbe9e0.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/80793f7c-4821-11ef-9913-b7d393cbe9e0.mp4',
    date_of_joining: '2024-03-01',
    certification: [],
    faculty_certification: 75,
    additional_info: {},
    specialization: 'Physiology',
    certification_count: 75,
    lecture: 58,
  },
  {
    id: 37,
    gender: '1',
    headline: 'MBBS, MS',
    name: 'Dr. Rakesh Mittal',
    total_experience: 20,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/43a8f6ca-70af-11ef-bc5f-32523c5f71bf.jpg',
    description:
      'Dr. Rakesh Mittal  Senior Consultant &  Faculty Surgery MBBS-  UCMS, Delhi university, Gold medalist DU Topper MS- Safdurjung  Hospital , DU •\tWorked in the biggest medical college of india UCMS, Safdurjung , MAMC , etc •\tTeaching surgery for over 20years •\tMy main  motto is to make surgery so much easy that you understand it .',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/43c5d420-70af-11ef-bc5f-32523c5f71bf.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/43ccf610-70af-11ef-bc5f-32523c5f71bf.mp4',
    date_of_joining: '2024-09-01',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Surgery',
    certification_count: 0,
    lecture: 43,
  },
  {
    id: 38,
    gender: '1',
    headline: 'MBBS',
    name: 'Dr. Paras Singh Jamwal',
    total_experience: 4,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/498601be-ae62-11ef-b27b-2a3bb414a4bb.jpg',
    description:
      'Dr. Paras singh jamwal  MBBS  Teaching experience 4 year’ 2 year’s JR ship experience in medicine . Popularly known as Chanting wallah for making difficult topics easy to remember for Exam',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/49a0672a-ae62-11ef-b27b-2a3bb414a4bb.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/49ad0fac-ae62-11ef-b27b-2a3bb414a4bb.mp4',
    date_of_joining: '2024-11-29',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Chanting Wallah',
    certification_count: 0,
    lecture: 0,
  },
  {
    id: 39,
    gender: '1',
    headline: 'MBBS',
    name: 'Dr. Vikash Jangir',
    total_experience: 2,
    image:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/profile-images/e9cfc7ee-b61a-11ef-beca-ee8211bb9654.jpg',
    description:
      'Dr Vikas Jangir  MBBS from Baba Raghav Das Medical College, Gorakhpur Guided and Mentored 1000+ Medical Students for various competitive exams.',
    portfolio: 'https://medwayprodblobendpoint.azureedge.net/medway-prod/pdf/e9d80daa-b61a-11ef-beca-ee8211bb9654.pdf',
    intro_video:
      'https://medwayprodblobendpoint.azureedge.net/medway-prod/video/e9e252ec-b61a-11ef-beca-ee8211bb9654.mp4',
    date_of_joining: '2024-12-09',
    certification: [],
    faculty_certification: null,
    additional_info: {},
    specialization: 'Nursing',
    certification_count: 0,
    lecture: 25,
  },
];
