import CommunityIcon from '@/assets/Icons/Homepage/community.svg';
import EducatorIcon from '@/assets/Icons/Homepage/educator.svg';
import GraduatedIcon from '@/assets/Icons/Homepage/graduated.svg';
import InnovationIcon from '@/assets/Icons/Homepage/innovation.svg';
import BatchImage1 from '@/assets/Images/Reels/batch1.png';
import BatchImage2 from '@/assets/Images/Reels/batch2.png';
import BatchImage3 from '@/assets/Images/Reels/batch3.png';
import BatchImage4 from '@/assets/Images/Reels/batch4.png';

export const featuresData = [
  {
    id: 1,
    icon: EducatorIcon,
    title: 'Expert Medical Educators',
    description: `Founded by expert medical educators, Medway understands the challenges of medical exams. Committed to student success, we've become a trusted name in medical education and support.`,
  },
  {
    id: 2,
    icon: GraduatedIcon,
    title: 'Student-Centered Approach',
    description: `We believe every student can excel with the right support. Our programs are tailored to individual learning styles and needs, emphasizing personalized guidance to help students achieve their goals.`,
  },
  {
    id: 3,
    icon: InnovationIcon,
    title: 'Innovative, Modern Learning',
    description: `At Medway, we stay at the forefront of medical education by integrating the latest advancements in teaching techniques and technology, ensuring students receive the most effective and relevant preparation.`,
  },
  {
    id: 4,
    icon: CommunityIcon,
    title: 'Collaborative Environment',
    description: `Our facilities are equipped with modern teaching aids, fostering a collaborative, motivating atmosphere where students can engage in peer learning and knowledge sharing for a comprehensive educational experience.`,
  },
];

export const coursesData = [
  {
    id: 1,
    title: 'NEET PG Exam Preparation',
    description:
      'Our NEET PG preparation program offers expert-led classes, detailed coverage of the syllabus, interactive discussions, and targeted practice sessions to ensure your success.',
    // link: '/neet-pg-preparation',
  },
  {
    id: 2,
    title: 'NEET UG Exam Preparation',
    description:
      'Our comprehensive program covers all subjects included in the NEXT Exam syllabus, featuring expert-led classes, interactive discussions, and practice sessions.',
    // link: '/neet-ug-preparation',
  },
  {
    id: 3,
    title: 'FMGE Exam Preparation',
    description:
      'Our FMGE preparation course is designed specifically for overseas medical graduates, offering extensive support in navigating the exam format and syllabus.',
    // link: '/fmge-preparation',
  },
];

export const instagramData = [
  { src: BatchImage1, url: 'link_to_instagram_post1' },
  { src: BatchImage2, url: 'link_to_instagram_post2' },
  { src: BatchImage3, url: 'link_to_instagram_post2' },
  { src: BatchImage4, url: 'link_to_instagram_post2' },
];
