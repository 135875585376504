import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { FacultyCardProps } from '@/types/const';

const FacultyCard = ({
  name,
  headline,
  total_experience,
  description,
  image,
  gradient,
}: FacultyCardProps & { gradient: string }) => {
  return (
    <div className="relative mb-1 flex h-[19rem] max-w-[22.5rem] flex-col justify-between rounded-lg border border-black bg-white font-inter shadow-md">
      <div className={`absolute bottom-0 right-0 z-10 size-48 ${gradient} rounded-lg`}></div>
      <i className="line-clamp-4 px-6 pt-4 text-sm font-medium leading-relaxed tracking-wider text-slate-gray">
        {description}
      </i>
      <Link href="/our-faculty" className="px-6 text-green-500">
        Know more →
      </Link>
      <div className="z-20 mt-4 flex h-32 items-end justify-between">
        <div className="pb-4 pl-5">
          <h3 className="text-base font-semibold">{name}</h3>
          <p className="text-xs text-gray-500">{headline}</p>
          <p className="text-[0.7rem] tracking-tighter text-gray-500">
            Working Experience - {total_experience || '2'}yrs
          </p>
        </div>
        <Image
          src={image}
          alt={name}
          width={500}
          height={500}
          className="z-10 size-32 self-end rounded-br-lg bg-transparent"
        />
      </div>
    </div>
  );
};

export default FacultyCard;
